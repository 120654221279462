import { Guid, CMDDateTime } from "./CMDTypes";
import { EventUser } from "./userTypes.interface";

export interface IEventSession {
  id: Guid;
  title: string;
  description?: string;
  sessionTime: CMDDateTime;
  location: string;
  speakers?: EventUser[];
  recordings: RecordingInfo[];
  meetingContext: MeetingContext;
}

interface MeetingContext {
  joinLink: string;
}

export type IEventSessionResponse = IEventSession[];

export type ISharingLinkPreviewResponse = {
  getUrl: string;
};

export interface RecordingInfo {
  id: Guid;
  expirationDate: string;
  publishProgress: RecordingPublishProgress;
  duration: string;
  hasTranscript: boolean;
  hostType: RecordingHostType;
}

export interface RecordingPublishProgress {
  status: RecordingPublishStatus;
  timeStamp: string;
}

export enum RecordingPublishStatus {
  Scheduled = "Scheduled",
  InProgress = "InProgress",
  Published = "Published",
  Failed = "Failed",
  Expired = "Expired",
}

export enum RecordingHostType {
  AMS = "AMS",
  SPE = "SPE",
}

export interface IRecordingPlaybackInfo {
  id: Guid;
  streamingUrl: string;
  redeemUrl: string;
  token: string;
}
