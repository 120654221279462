import { ReportHandler, Metric } from "web-vitals";
import { Logger, Scenario } from "../../common/logger/Logger";

// See https://web.dev/vitals for information on web vitals in general
// or https://www.npmjs.com/package/web-vitals for info on the package we're using to collect them

export const routeWithoutGuid = (url: string) => {
  return url.replace(
    /^(https:\/\/[a-zA-Z0-9.-]+\.teams\.microsoft\.com\/[a-zA-Z0-9-]+\/).*$/,
    "$1"
  );
};

export const captureWebVitals = (onPerfEntry?: ReportHandler): void => {
  /* istanbul ignore else */
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry); // https://web.dev/cls/
      getFID(onPerfEntry); // https://web.dev/fid/
      getFCP(onPerfEntry); // https://web.dev/fcp/
      getLCP(onPerfEntry); // https://web.dev/lcp/
      getTTFB(onPerfEntry); // https://web.dev/ttfb/
    });
  }
};

// Use for local debugging. Swap reportWebVitalsTelemetry for printWebVitals in index.tsx
export const printWebVitals = (webVitalsMetric: Metric): void => {
  console.log(
    `web vitals metric for ${routeWithoutGuid(
      window.location.href
    )}: ${JSON.stringify(webVitalsMetric)}`
  );
};

export const reportWebVitalsTelemetry: ReportHandler = (metric: Metric) => {
  const logger = Logger.getInstance();
  // Remove GUIDs so we can get metrics on pages as a whole (eg /event, /event/registration), regardless of particular event
  const scenario = logger.createScenario(Scenario.WebVitalsMetric, {
    data: { route: routeWithoutGuid(window.location.href) },
  });
  logger.logPerformance(
    metric.name,
    true,
    metric.value,
    undefined,
    undefined,
    scenario || /* istanbul ignore next */ undefined
  );
  scenario?.stop();
};
